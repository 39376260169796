<template>
  <div id="app" >
     <NavBar style="padding: 30px;"/>
     <div class="pt-5 flex justify-center bg-gray-100" style="padding-bottom: 100%;">
      <router-view />
     </div>
     <div class="footerSvg text-center bg-gray-700 pt-2">
      <span>EZCargas.com</span>
     <CardSvg />
     <ServicePay/>
     </div>
     <FooterHome class="footerHome"/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterHome from '@/components/FooterHome.vue';
import CardSvg from '@/components/CardsSvg.vue';
import ServicePay from '@/components/ServicePay.vue';

export default {
  components:{
    NavBar,
    FooterHome,
    CardSvg,
    ServicePay
  }
}
</script>

<style>
.footerSvg{
  position: fixed;
  bottom: 17px;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 9999;
}
.footerHome {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  justify-content: center;
  z-index: 9999;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}
</style>
