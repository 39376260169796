<template>
    <div class="w-max m-2">
      <div class="flex items-center mb-5">
          <svg class="w-6 h-6 mr-5 text-gray-800 collapse-label" @click="goBack()" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 5H1m0 0 4 4M1 5l4-4"/>
          </svg>
          <h2 class="text-lg text-gray-800 font-bold">Seleccionar Servicio</h2>
        </div>
      <div class="collapse-wrapper bg-white shadow-lg" style="border-radius: 15px;" v-for="(item, index) in items" :key="index">
        <div class="m-5 flex justify-between items-center collapse-label" @click="guardarServicio(item.img, item.title),toggleCollapse(item.name)">
          <div class="flex items-center">
            <img :src="item.img" style="width: 35px; margin: 0px;" alt="Logo" class="rounded">
            <div class="collapse-title text-gray-800 ml-2">
              {{ item.title }}
            </div>
          </div>
          <svg class="h-6 w-6 fill-current md:h-8 md:w-8" style="color: black;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
          </svg>
        </div>
      </div>
    </div>
  </template>

  <script>
    import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        items: [
          {
            title: 'Sube',
            img: 'https://play-lh.googleusercontent.com/gQ0nbnaHaX1imHvG8v-oSvRMHklA9CN7nHPrje6ZvPDeLiv7ueOMmB_mgZHKUIfWQqU',
            name: 'bus-info'
          },
          {
            title: 'Red Bus Córdoba',
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBIXwqWPAGj24Y0ECErY_TIZOD68JDJhKxu4yewAmgsg&s',
            name:'bus-info'
          },
          {
            title: 'Metropolitana',
            img: 'https://pbs.twimg.com/profile_images/2653619486/29dd69654fcf843b11a9e18b34afe50e_400x400.jpeg',
            name: 'bus-info'
          },
          {
            title: 'Ciudadana',
            img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Pwpo0-VRlLFuJmW2dC_FwDQhIpPBH92Vek6J2SOmeUdmW1-VYBCI4U8byp0mS9-TQUQ&usqp=CAU',
            name: 'bus-info'
          },
          {
            title: 'Saeta',
            img: 'https://pbs.twimg.com/profile_images/1653765788119990272/nUdoX6BH_400x400.jpg',
            name:'bus-info'
          }
        ]
      };
    },
    methods: {
      ...mapActions(['setServicio', 'setDeleteService', 'setDatos', 'setDeleteDatos', 'limpiarServicio', 'limpiarDatos']),

      guardarServicio(img, title) {
        this.limpiarServicios();
        let nuevoServicio = {
            "logo": img,
            "name": title
          };
        if (nuevoServicio) {
          this.setServicio(nuevoServicio);
          nuevoServicio = '';
        }
      },
      limpiarServicios() {
        this.limpiarServicio();
      },
      toggleCollapse(index) {
        this.$router.push(`/${index}`);
      },
      goBack() {
    this.$router.go(-1);
  }
    }
  }
  </script>
  
  <style>
  .collapse-wrapper {
    margin-bottom: 0.5rem;
  }
  
  .collapse-label {
    cursor: pointer;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  