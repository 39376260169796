<template>
    <div class="flex justify-center items-center" style="padding-bottom: 10%;">
      <div class="flex flex-col items-center justify-center">
        <div class="flex items-center mb-5">
          <svg class="w-6 h-6 mr-5 text-gray-800 collapse-label" @click="goBack()" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 5H1m0 0 4 4M1 5l4-4"/>
          </svg>
          <h2 class="text-lg text-gray-800 font-bold">Ingresar datos</h2>
        </div>
        <div class="flex flex-col items-center justify-center mb-5 mt-5">
                <img class="w-20 rounded mb-5 mt-5" :src="servicios.logo" alt="">
        </div>
        <div class="w-35">
          <div class="relative z-0 mb-5 group">
            <input type="phone" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"></label>
            <div>
              <span class="text-gray-400">Número de tarjeta</span>
            </div>
          </div>
          <div class="relative z-0 mb-5 group">
            <input v-model="numberId" type="phone" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
            <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-800 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"></label>
            <div>
              <span class="text-gray-400">Número de DNI</span>
            </div>
          </div>
          <div @click="guardarDato()"  class="justify-center text-green-600">
            <router-link :to="{
                name: 'prices-data'
            }">
                Continuar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { mapGetters, mapActions } from 'vuex';
  export default {
    data(){
        return{
          numberId:'',
          servicios:{}
        }
    },
    computed: {
      ...mapGetters(['getServicio', 'getDatos']),
    },
    mounted(){
        this.servicios= this.getServicio[0]
        this.datos= this.getDatos
    },
    methods:{
        ...mapActions(['setServicio', 'setDeleteService', 'setDatos', 'setDeleteDatos', 'limpiarServicio', 'limpiarDatos']),

    guardarDato() {
        this.limpiarDatos();
        let nuevoDato ={
            "tel": 'Sin datos',
            "dni": this.numberId 
        }
      this.setDatos(nuevoDato);
    },
      goBack() {
    this.$router.go(-1);
  }
    }
  }
 </script>
  
  <style>
  .bottom-border {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid darkblue;
    outline: none; 
  }
  </style>
  