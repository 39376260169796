<template>
  <div class="w-max m-2">
<!--Carousel Promos-->
    <div>
      <!-- <FinDeSemana /> -->
    </div>
    <div
      class="collapse-wrapper bg-white shadow-lg"
      style="border-radius: 15px;"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="m-5 flex justify-between items-center collapse-label" @click="toggleCollapse(item.name)">
        <div class="collapse-title text-gray-800 flex items-center">
          <div v-html="item.img" class="mr-2"></div> <!-- Agregamos un margen a la derecha para separar el contenido -->
          <span>{{ item.title }}</span>
        </div>
        <svg
          class="h-6 w-6 fill-current md:h-8 md:w-8"
          style="color: black;"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
        </svg>
      </div>
    </div>
  </div>
</template>

  
  <script>
  import FinDeSemana from '@/components/carousel/FinDeSeamana.vue'
  export default {
    components:{
      FinDeSemana
    },
    data() {
      return {
        items: [
          {
            title: 'Teléfono',
            img: `<svg class="svg-icon text-gray-800 w-6 h-6" viewBox="0 0 20 20">
                  <path d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z"></path>
                </svg>`,
            name: 'phone'
          },
          {
            title: 'Tv Satelital',
            img: `<svg class="svg-icon text-gray-800 w-6 h-6" viewBox="0 0 20 20">
							<path d="M17.237,3.056H2.93c-0.694,0-1.263,0.568-1.263,1.263v8.837c0,0.694,0.568,1.263,1.263,1.263h4.629v0.879c-0.015,0.086-0.183,0.306-0.273,0.423c-0.223,0.293-0.455,0.592-0.293,0.92c0.07,0.139,0.226,0.303,0.577,0.303h4.819c0.208,0,0.696,0,0.862-0.379c0.162-0.37-0.124-0.682-0.374-0.955c-0.089-0.097-0.231-0.252-0.268-0.328v-0.862h4.629c0.694,0,1.263-0.568,1.263-1.263V4.319C18.5,3.625,17.932,3.056,17.237,3.056 M8.053,16.102C8.232,15.862,8.4,15.597,8.4,15.309v-0.89h3.366v0.89c0,0.303,0.211,0.562,0.419,0.793H8.053z M17.658,13.156c0,0.228-0.193,0.421-0.421,0.421H2.93c-0.228,0-0.421-0.193-0.421-0.421v-1.263h15.149V13.156z M17.658,11.052H2.509V4.319c0-0.228,0.193-0.421,0.421-0.421h14.308c0.228,0,0.421,0.193,0.421,0.421V11.052z"></path>
						</svg>`,
            name: 'tv'
          },
          // {
          //   title: 'Recargar Free Fire',
          //   img: '',
          //   name: 'games'
          // },
          // {
          //   title: 'Pagar Internet',
          //   img: '',
          //   name: 'internet'
          // },
          {
            title: 'Transporte',
            img: `<svg class="svg-icon text-gray-800 w-6 h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
            <defs>
            </defs>
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
              <path d="M 88.179 30.195 c -2.019 -7.928 -6.212 -11.62 -13.197 -11.62 H 4.706 C 2.111 18.574 0 20.685 0 23.281 v 35.948 c 0 2.248 1.83 4.078 4.079 4.078 h 4.858 c 0.576 4.57 4.478 8.119 9.202 8.119 s 8.626 -3.549 9.202 -8.119 H 66.23 c 0.576 4.57 4.479 8.119 9.203 8.119 s 8.626 -3.549 9.202 -8.119 h 1.316 c 2.232 0 4.049 -1.817 4.049 -4.049 C 90.008 46.054 89.885 36.898 88.179 30.195 z M 19.058 40.746 c -0.152 0 -0.276 -0.124 -0.276 -0.276 V 29.988 c 0 -0.152 0.124 -0.276 0.276 -0.276 h 14.795 v 11.033 H 19.058 z M 36.819 29.713 h 15.07 v 11.033 h -15.07 V 29.713 z M 54.856 29.713 h 15.07 v 11.033 h -15.07 V 29.713 z M 72.893 29.713 h 12.071 c 0.119 0.39 0.232 0.794 0.339 1.214 c 1.024 4.02 1.447 9.088 1.618 15.542 c -4.406 -3.381 -9.025 -5.24 -14.028 -5.642 V 29.713 z M 18.138 68.458 c -3.482 0 -6.316 -2.833 -6.316 -6.316 c 0 -3.483 2.833 -6.316 6.316 -6.316 c 3.482 0 6.316 2.833 6.316 6.316 C 24.453 65.625 21.62 68.458 18.138 68.458 z M 75.433 68.458 c -3.483 0 -6.317 -2.833 -6.317 -6.316 c 0 -3.483 2.834 -6.316 6.317 -6.316 s 6.316 2.833 6.316 6.316 C 81.748 65.625 78.915 68.458 75.433 68.458 z M 85.951 60.339 h -1.414 c -0.842 -4.258 -4.602 -7.479 -9.104 -7.479 s -8.263 3.221 -9.105 7.479 H 27.241 c -0.842 -4.258 -4.602 -7.479 -9.104 -7.479 s -8.262 3.221 -9.104 7.479 H 4.078 c -0.613 0 -1.112 -0.498 -1.112 -1.111 V 23.281 c 0 -0.959 0.78 -1.739 1.74 -1.739 h 70.275 c 4.192 0 6.978 1.598 8.813 5.204 H 19.058 c -1.788 0 -3.243 1.455 -3.243 3.243 V 40.47 c 0 1.788 1.455 3.243 3.243 3.243 l 52.318 0 c 5.604 0.129 10.718 2.328 15.619 6.68 c 0.033 2.728 0.039 5.674 0.037 8.864 C 87.032 59.854 86.547 60.339 85.951 60.339 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
              <circle cx="18.142" cy="62.142" r="2.122" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
              <circle cx="75.432" cy="62.142" r="2.122" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
            </g>
            </svg>`,
            name: 'bus'
          }
        ]
      };
    },
    methods: {
      toggleCollapse(index) {
        this.$router.push(`/${index}`);
      }
    }
  }
  </script>
  
  <style>
  .collapse-wrapper {
    margin-bottom: 0.5rem;
  }
  
  .collapse-label {
    cursor: pointer;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  