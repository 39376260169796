import { createRouter, createWebHistory } from 'vue-router';

import HomeView from '../views/HomeView.vue';
import BusHome from '../views/BusHome.vue';
import AntinaHome from '../views/AntinaHome.vue';
import DirecTvHome from '../views/DirecTvHome.vue';
import InternetHome from '../views/InternetHome.vue';
import PhoneHome from '../views/PhoneHome.vue';
import FreeFire from '../views/FreeFire.vue';
import Contacto from '../views/Contacto.vue';

// Components Data
import BusData from '../views/BusData.vue';
import TvData from '../views/TvData.vue';
import PhoneData from '../views/PhoneData.vue';
import PricesList from '../views/PricesList.vue';
import PhoneCheckout from '../views/Checkout.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/bus',
    name: 'bus-home',
    component: BusHome
  },
  {
    path: '/antina-plans',
    name: 'antina-home',
    component: AntinaHome
  },
  {
    path: '/tv',
    name: 'tv-home',
    component: DirecTvHome
  },
  // {
  //   path: '/internet',
  //   name: 'internet-home',
  //   component: InternetHome
  // },
  {
    path: '/phone',
    name: 'phone-home',
    component: PhoneHome
  },
  // {
  //   path: '/games',
  //   name: 'games-home',
  //   component: FreeFire
  // },
  {
    path: '/bus-info',
    name: 'bus-data',
    component: BusData
  },
  {
    path: '/tv-info',
    name: 'tv-data',
    component: TvData
  },
  {
    path: '/phone-info',
    name: 'phone-data',
    component: PhoneData
  },
  {
    path: '/prices-info',
    name: 'prices-data',
    component: PricesList
  },
  {
    path: '/checkout',
    name: 'check-phone',
    component: PhoneCheckout
  },
  {
    path: '/contacto',
    name: 'contacto-form',
    component: Contacto
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
