<template>
    <div>
        <div class="navbar text-red-600 font-bold justify-center relative rounded bg-gradient-to-r from-gray-700 to-blue-300">
      <svg class="w-20 h-20 mr-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
        <defs>
        </defs>
        <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
          <path d="M 56.971 88.104 c -0.933 0 -1.882 -0.209 -2.781 -0.648 l -8.18 -3.998 c -0.643 -0.314 -1.38 -0.314 -2.02 0 l -8.18 3.998 c -3.069 1.504 -6.728 0.312 -8.331 -2.706 l -4.267 -8.043 c -0.335 -0.632 -0.93 -1.064 -1.634 -1.187 l -8.968 -1.573 c -3.366 -0.591 -5.627 -3.704 -5.149 -7.087 l 1.275 -9.015 c 0.099 -0.697 -0.134 -1.416 -0.623 -1.921 L 1.782 49.38 c -2.377 -2.455 -2.377 -6.304 0 -8.76 l 6.33 -6.543 c 0.497 -0.514 0.724 -1.214 0.624 -1.921 l -1.275 -9.015 c -0.478 -3.383 1.784 -6.497 5.149 -7.087 l 8.967 -1.573 c 0.704 -0.124 1.3 -0.557 1.635 -1.188 l 4.267 -8.043 c 1.601 -3.019 5.261 -4.209 8.331 -2.707 l 8.179 3.998 c 0.641 0.313 1.378 0.313 2.021 0 l 8.18 -3.998 c 3.069 -1.502 6.729 -0.312 8.33 2.707 l 4.268 8.042 c 0.335 0.632 0.93 1.064 1.634 1.188 l 8.968 1.573 c 3.365 0.591 5.627 3.704 5.148 7.087 l -1.275 9.015 c -0.101 0.707 0.128 1.408 0.625 1.921 l 6.33 6.543 c 2.376 2.456 2.376 6.304 0 8.76 l -6.33 6.543 c -0.497 0.514 -0.725 1.214 -0.625 1.922 l 1.275 9.014 c 0.478 3.383 -1.783 6.496 -5.148 7.087 l -8.968 1.573 c -0.705 0.123 -1.3 0.556 -1.633 1.186 L 62.52 84.75 C 61.388 86.884 59.225 88.103 56.971 88.104 z M 46.888 8.339 h 0.01 H 46.888 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(226,61,61); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 21.678 34.103 c -1.104 0 -2 -0.895 -2 -2 v -7.128 c 0 -1.104 0.895 -2 2 -2 h 7.684 c 1.104 0 2 0.895 2 2 s -0.895 2 -2 2 h -5.684 v 5.129 C 23.678 33.207 22.782 34.103 21.678 34.103 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 26.285 44.149 h -4.606 c -1.104 0 -2 -0.895 -2 -2 s 0.895 -2 2 -2 h 4.606 c 1.667 0 3.024 -1.357 3.024 -3.024 s -1.357 -3.023 -3.024 -3.023 h -4.606 c -1.104 0 -2 -0.895 -2 -2 s 0.895 -2 2 -2 h 4.606 c 3.872 0 7.023 3.15 7.023 7.022 S 30.157 44.149 26.285 44.149 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 42.673 44.149 c -3.962 0 -7.186 -3.224 -7.186 -7.186 v -6.801 c 0 -3.962 3.224 -7.186 7.186 -7.186 s 7.186 3.224 7.186 7.186 v 6.801 C 49.859 40.925 46.635 44.149 42.673 44.149 z M 42.673 26.974 c -1.757 0 -3.187 1.43 -3.187 3.187 v 6.801 c 0 1.757 1.43 3.187 3.187 3.187 c 1.757 0 3.187 -1.43 3.187 -3.187 v -6.801 C 45.86 28.404 44.43 26.974 42.673 26.974 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 55.802 44.226 c -0.386 0 -0.775 -0.111 -1.119 -0.344 c -0.915 -0.619 -1.154 -1.862 -0.535 -2.777 l 11.028 -16.29 c 0.619 -0.915 1.861 -1.155 2.777 -0.535 c 0.915 0.619 1.154 1.862 0.535 2.777 L 57.46 43.347 C 57.073 43.918 56.444 44.226 55.802 44.226 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <circle cx="57.179" cy="26.569" r="2.999" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
          <circle cx="66.179" cy="40.949000000000005" r="2.999" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
          <path d="M 31.868 69.574 c -3.962 0 -7.186 -3.224 -7.186 -7.186 v -6.801 c 0 -3.962 3.224 -7.186 7.186 -7.186 s 7.186 3.224 7.186 7.186 v 6.801 C 39.055 66.351 35.831 69.574 31.868 69.574 z M 31.868 52.401 c -1.757 0 -3.187 1.429 -3.187 3.187 v 6.801 c 0 1.757 1.43 3.187 3.187 3.187 c 1.757 0 3.187 -1.429 3.187 -3.187 v -6.801 C 35.056 53.83 33.626 52.401 31.868 52.401 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 44.615 69.574 c -1.104 0 -2 -0.895 -2 -2 V 50.401 c 0 -1.104 0.895 -2 2 -2 h 6.465 c 1.104 0 2 0.895 2 2 s -0.895 2 -2 2 h -4.466 v 15.174 C 46.615 68.679 45.72 69.574 44.615 69.574 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 51.081 60.988 h -6.465 c -1.104 0 -2 -0.895 -2 -2 s 0.895 -2 2 -2 h 6.465 c 1.104 0 2 0.895 2 2 S 52.185 60.988 51.081 60.988 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 58.34 69.574 c -1.104 0 -2 -0.895 -2 -2 V 50.401 c 0 -1.104 0.895 -2 2 -2 h 6.465 c 1.104 0 2 0.895 2 2 s -0.895 2 -2 2 h -4.466 v 15.174 C 60.339 68.679 59.444 69.574 58.34 69.574 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
          <path d="M 64.805 60.988 H 58.34 c -1.104 0 -2 -0.895 -2 -2 s 0.895 -2 2 -2 h 6.465 c 1.104 0 2 0.895 2 2 S 65.909 60.988 64.805 60.988 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
        </g>
        </svg>
        <div class="flex flex-col">
          <h3 class="text-yellow-800">SÁBADOS Y DOMINGOS!</h3>
          <div class="text-sm font-sm text-yellow-700">Hasta $10.000</div>
        </div>
    </div>
    </div>
</template>

<style></style>