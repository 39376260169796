<template>
    <div>
        <footer class="footer footer-center p-2 bg-base-300 text-base-content">
  <aside>
    <p>Copyright © 2024 - All right reserved by ACME</p>
  </aside>
</footer>
    </div>
</template>

<script>
</script>

<style>
</style>