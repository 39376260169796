<template>
    <div class="w-max m-2">
      <div class="flex items-center mb-5">
          <svg class="w-6 h-6 mr-5 text-gray-800 collapse-label" @click="goBack()" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M13 5H1m0 0 4 4M1 5l4-4"/>
          </svg>
          <h2 class="text-lg text-gray-800 font-bold">Seleccionar Servicio</h2>
        </div>
      <div class="collapse-wrapper bg-white shadow-lg" style="border-radius: 15px;" v-for="(item, index) in items" :key="index">
        <div class="m-5 flex justify-between items-center collapse-label" @click="guardarServicio(item.img, item.title),toggleCollapse(item.name)">
          <div class="flex items-center">
            <img :src="item.img" style="width: 20px;" alt="Logo">
            <div class="collapse-title text-gray-800 ml-2">
              {{ item.title }}
            </div>
          </div>
          <svg class="h-6 w-6 fill-current md:h-8 md:w-8" style="color: black;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
          </svg>
        </div>
      </div>
    </div>
  </template>
  
  <script>
     import { mapGetters, mapActions } from 'vuex';
  export default {
    data() {
      return {
        items: [
          {
            title: 'DirecTv',
            img: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/The_DirecTV_logo.png',
            name: 'tv-info'
          },
          {
            title: 'Antina',
            img: 'https://media.licdn.com/dms/image/C4D0BAQGhU9zh-riHKQ/company-logo_200_200/0/1645617784561/antina_empleos_logo?e=2147483647&v=beta&t=i2Jjkpjm51MAkrmTOyQMMoaNEuJLivb6OABfswoy_K8',
            name: 'antina-plans'
          },
        ]
      };
    },
    methods: {
      ...mapActions(['setServicio', 'setDeleteService', 'setDatos', 'setDeleteDatos', 'limpiarServicio', 'limpiarDatos']),

        guardarServicio(img, title) {
          this.limpiarServicios();
          let nuevoServicio = {
              "logo": img,
              "name": title
            };
          if (nuevoServicio) {
            this.setServicio(nuevoServicio);
            nuevoServicio = '';
          }
        },
        limpiarServicios() {
          this.limpiarServicio();
        },
      toggleCollapse(index) {
        this.$router.push(`/${index}`);
      },
      goBack() {
    this.$router.go(-1);
  }
    }
  }
  </script>
  
  <style>
  .collapse-wrapper {
    margin-bottom: 0.5rem;
  }
  
  .collapse-label {
    cursor: pointer;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  